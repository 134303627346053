var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-row',[_c('b-col',{attrs:{"md":"7","lg":"8","cols":"12"}},[_c('b-card',{attrs:{"title":"Пауза пени","border-variant":"light"}},[_c('b-overlay',{attrs:{"show":_vm.show,"no-wrap":""}}),_c('b-table',{attrs:{"items":_vm.finePauses,"fields":_vm.finePausesFields,"show-empty":"","bordered":"","empty-text":"Нет данных","responsive":""},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.deleteFinePause(row.item.id)}}},[_vm._v(" Удалить ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5","lg":"4","cols":"12"}},[_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.show,"no-wrap":""}}),_c('b-form-group',{attrs:{"label":"Комментарий"}},[_c('b-form-textarea',{model:{value:(_vm.finePause.comment),callback:function ($$v) {_vm.$set(_vm.finePause, "comment", $$v)},expression:"finePause.comment"}})],1),_c('b-form-group',{attrs:{"label":"Дата начала"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder","locale":"ru","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"initial-date":_vm.today},model:{value:(_vm.finePause.start_date),callback:function ($$v) {_vm.$set(_vm.finePause, "start_date", $$v)},expression:"finePause.start_date"}})],1),_c('b-form-group',{attrs:{"label":"Дата окончания"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder","locale":"ru","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"initial-date":_vm.today},model:{value:(_vm.finePause.end_date),callback:function ($$v) {_vm.$set(_vm.finePause, "end_date", $$v)},expression:"finePause.end_date"}})],1),_c('b-button',{staticClass:"mt-1",on:{"click":_vm.addFinePause}},[_vm._v(" Добавить паузу по пене")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"7","lg":"8","cols":"12"}},[_c('b-card',{attrs:{"title":"Пауза процентов","border-variant":"light"}},[_c('b-overlay',{attrs:{"show":_vm.show,"no-wrap":""}}),_c('b-table',{attrs:{"items":_vm.percentPauses,"fields":_vm.percentPausesFields,"show-empty":"","bordered":"","empty-text":"Нет данных","responsive":""},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.deletePercentPause(row.item.id)}}},[_vm._v(" Удалить ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5","lg":"4","cols":"12"}},[_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.show,"no-wrap":""}}),_c('b-form-group',{attrs:{"label":"Комментарий"}},[_c('b-form-textarea',{model:{value:(_vm.percentPause.comment),callback:function ($$v) {_vm.$set(_vm.percentPause, "comment", $$v)},expression:"percentPause.comment"}})],1),_c('b-form-group',{attrs:{"label":"Дата начала"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder","locale":"ru","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"initial-date":_vm.today},model:{value:(_vm.percentPause.start_date),callback:function ($$v) {_vm.$set(_vm.percentPause, "start_date", $$v)},expression:"percentPause.start_date"}})],1),_c('b-form-group',{attrs:{"label":"Дата окончания"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder","locale":"ru","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"initial-date":_vm.today},model:{value:(_vm.percentPause.end_date),callback:function ($$v) {_vm.$set(_vm.percentPause, "end_date", $$v)},expression:"percentPause.end_date"}})],1),_c('b-button',{staticClass:"mt-1",on:{"click":_vm.addPercentPause}},[_vm._v(" Добавить паузу по процентам")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"7","lg":"8","cols":"12"}},[_c('b-card',{attrs:{"title":"Льготный период","border-variant":"light"}},[_c('b-overlay',{attrs:{"show":_vm.show,"no-wrap":""}}),_c('b-table',{attrs:{"items":_vm.gracePeriods,"fields":_vm.gracePeriodsFields,"show-empty":"","bordered":"","empty-text":"Нет данных","responsive":""},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.deleteGracePeriod(row.item.id)}}},[_vm._v(" Удалить ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5","lg":"4","cols":"12"}},[_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.show,"no-wrap":""}}),_c('b-form-group',{attrs:{"label":"Комментарий"}},[_c('b-form-textarea',{model:{value:(_vm.gracePeriod.comment),callback:function ($$v) {_vm.$set(_vm.gracePeriod, "comment", $$v)},expression:"gracePeriod.comment"}})],1),_c('b-form-group',{attrs:{"label":"Дата начала"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder","locale":"ru","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"initial-date":_vm.today},model:{value:(_vm.gracePeriod.start_date),callback:function ($$v) {_vm.$set(_vm.gracePeriod, "start_date", $$v)},expression:"gracePeriod.start_date"}})],1),_c('b-form-group',{attrs:{"label":"Дата окончания"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder","locale":"ru","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            },"initial-date":_vm.today},model:{value:(_vm.gracePeriod.end_date),callback:function ($$v) {_vm.$set(_vm.gracePeriod, "end_date", $$v)},expression:"gracePeriod.end_date"}})],1),_c('b-button',{staticClass:"mt-1",on:{"click":_vm.addGracePeriod}},[_vm._v(" Добавить льготный период")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }